//Tipos de salidas 
module.exports = {

    in: {
        dispatch: 1,// Por despacho
        externProvider: 2,// suministro
        storeRelocation: 3,// Proviene de traslado de tienda
    },

    inDescription: {
        1: 'Proviene de fabrica fattoria',
        2: 'suministro',
        3: 'Proviene de traslado de tienda',
    },

    out: {
        used: 1,// Suministros usados
        transfer: 2,//Traslado
        trash: 3,// Desechos
    },

    outDescription: {
        1: 'Suministros usados',
        2: 'Traslado entre tiendas',
        3: 'Desechos',
    }
}